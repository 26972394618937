.forgot-password {
    background: #0C0C0C;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 29.5px 0 61px 0;
    width: 100%;
    box-sizing: border-box;
    position: relative; /* Añadido */
    z-index: 2; /* Añadido */
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.city-background {
    background: url('/src/pages/image/CityLoginVectorMesaDeTrabajo11.png') center / cover no-repeat;
    width: 100%;
    height: 100% ;
}

.overlay {
    background: url('/src/pages/image/NuevoFondoLogin021.png') center / cover no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 2; /* Añadido */
}

.logos {
    position: relative;
    margin: 0 0.7px 27px 0;
    display: flex;
    justify-content: center; /* Centra el logo horizontalmente dentro del contenedor */
    align-items: center; /* Centra el logo verticalmente dentro del contenedor */
    width: 115.5px; /* Ajustado al nuevo tamaño del logo */
    height: 124.5px; /* Ajustado para permitir más espacio vertical */
    box-sizing: border-box;
    z-index: 3;
}

.logo-forgot-password {
    width: 150.5px;
    height: 154.5px;
}


.Resetconfirm-container {
    opacity: 0.8;
    border-radius: 22px;
    background: #0C0C0C;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 89px 0 84px 0;
    width: 100%; /* Cambiado a 100% para ser responsivo */
    max-width: 400px; /* Define un ancho máximo */
    box-sizing: border-box;
    z-index: 3;
}


.Resetconfirm-icon{
    margin-bottom: 21px;
    display: flex;
    width: 113px;
    height: 113px;
    box-sizing: border-box;
  }
  .Resetconfirm-img {
    width: 113px;
    height: 113px;
  }

  
  .Resetconfirm-Title{
    margin-bottom: 21px;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #F0B41E;
    text-align: center;
  }
  
  .Resetconfirm-text{
    margin-bottom: 21px;
    display: inline-block;
    text-align: center;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 18px;
    line-height: 1.27;
    color: #8D9298;
    padding-left: 25px;
    padding-right: 25px;
  }

.highlight {
    color: #F0B41E;
}


.Resetconfirm-button {
    border-radius: 10px;
    background: #F0B41E;
    position: relative;
    margin-bottom: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.2px 0 11.7px 0;
    width: 100%; /* Cambiado a 100% para ser responsivo */
    max-width: 247px; /* Define un ancho máximo */
    box-sizing: border-box;
    cursor: pointer;
}


.Resetconfirm-button-text{
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #0C0C0C;
}



/* Media queries para ajustes responsivos */
@media (max-width: 768px) {
    .Resetconfirm-container {
        padding: 20px;
    }

   
    .Resetconfirm-button-text{
        font-size: 14px;
    }
}
  
.Resetconfirm-Subtext-plus{
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.333;
    color: #8D9298;
    padding-left: 25px;
    padding-right: 25px;
  }
  .Resetconfirm-Subtext {
    text-align: center;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 12px;
    line-height: 1.27;
    color: #8D9298;
    padding-left: 25px;
    padding-right: 25px;
  }
  