.DeleteUserButtons{
    position: relative;
    padding-top: 3vh;
    margin-left: 17vh;
    align-content: center;
}

@media (max-width:768px){
    .DeleteUserButtons{
        position: relative;
        padding-top: 3vh;
        margin-left: 8vh;
        align-content: center;
    }
}