.main-content-settings{
    position: absolute;
    background-color: rgb(12, 12, 12);
    width: 82%;
    height:116vh;
    right: 0;
    font-family: Manrope;
}

.main-content-settings h1{
    color: white;
    margin-top: 2%;
    margin-left: 2%;
}

.user-content a{
  text-decoration: none !important;
  color: white;
}
.settings-content{
    position: absolute;
    right: 2%;
    margin-top: 2%;

    background-color: rgb(24, 24, 24);
    width: 96%;
    height: 100vh;
    border-radius: 25px;
}

@media (max-width: 768px) {

/* Estilos generales */
.main-content-settings {
    position: relative;
    background-color: rgb(12, 12, 12);
    width: 100%;
    height: 100vh;
    padding: 1rem;
  }

  .main-content-settings h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .settings-content {
    position: relative;
    background-color: rgb(24, 24, 24);
    width: 100%;
    height: auto;
    padding: 1rem;
    margin-top: 5rem;
    border-radius: 15px;
  }

  .settings-content h3 {
    font-size: 1.2rem;
    text-align: center;
  }

  .MuiButton-root {
    font-size: 1rem;
    padding: 0.8rem;
    margin-top: 1rem;
    width: 100%;
  }

  .MuiTabs-root {
    display: flex;
    justify-content: center;
  }

  .MuiTab-root {
    min-width: 80px;
    font-size: 0.9rem;
  }
}