/* Ocultar scrollbar en navegadores Webkit (Chrome, Safari, etc.) */
body::-webkit-scrollbar {
    display: none;
  }



  
/* Estilos generales */
.files-container{
    background-color: rgb(12, 12, 12);
    width: 100%;
    height: 127vh;
}

.main-content-files{
    position: absolute;
    background-color: rgb(24, 24, 24);
    width: 78%;
    left: 44.5vh;
    top: 15vh;
    border-radius: 25px;
    padding-bottom: 110vh;
}

.main-title-files{
    /*position*/
    position: absolute;
    left: 45vh;
    top: 4vh;
    /*style*/
    color: white;
    font-family: Manrope;
}

.main-content-files h2{
    /*position*/
    position: absolute;
    top: 3vh;
    left: 3vh;
    /*style*/
    color: white;
    font-family: Manrope;
    font-size: 4vh;
}

.main-content-files h3{
    /*position*/
    position: absolute;
    top: 38vh;
    left: 3vh;
    /*style*/
    color: white;
    font-family: Manrope;
    font-size: 4vh;
}

.folders-container{
    /*position*/
    position: absolute;
    left: 4.2vh;
    top: 9vh;
    /*style*/
    background-color: rgb(12, 12, 12);
    width: 95%;
    height: 25vh;
    border-radius: 25px;
    overflow-y: auto; /* Añade scroll vertical */
}

.folder-files-container{
    /*position*/
    position: absolute;
    top: 12vh;
    left: 4.2vh;
    /*style*/
    background-color: rgb(12, 12, 12);
    width: 95%;
    height: 91.5vh;
    border-radius: 25px;
    overflow-y: auto; /* Añade scroll vertical */
}

.content-table{
    position: relative;
    width: 90%;
    height: 86%;
    margin-left: 5%;
    border-radius: 10px;
    top: 7vh;
    text-align:justify;
}

.activity-table th{
    background-color: rgb(24, 24, 24);
    color: white;
    text-align: left;
}

.back-button{
    position: absolute;
    top: 1.6%;
    left: 70%;
}

.hidden-back-button{
    display: none;
}

.download-button{
    background-color: brown;
}
/*put the borders round*/
.th1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius:10px ;
    text-align-last: left;
  }

  .th2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

.activity-table{
    color: white;
}

.next-text{
    color: rgb(249, 180, 30);
}

.page-numbers{
    color: rgb(249, 180, 30);
}

.createFolderDiv{
    position: absolute;
    top: 1.5%;
    right: 5%;
}

@media (max-width:768px){
    .main-content-files{
        top: 10%;
        left: 1%;
        /*style*/
        width: 98%;

    }

    .main-title-files{
        top: 3%;
        left: 18%;
    }

    .folder-files-container, .folders-container{
        width: 96%;
        left: 2%;
    }

    .back-button{
        
        top: 6%;
        left: 78%;
        
    }

    .main-content-files h2{
        /*position*/
        top: 1vh;
     
    }
}