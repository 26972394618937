.upload-status-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400!important;
  }
  
  .upload-status-modal-content {
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 400px; /* Más pequeño que la ventana de subida */
    color: white;
    border: 2px solid rgb(255, 255, 255);
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  