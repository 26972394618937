.FolderAccess-Container {
    box-shadow: 7px 8px 11px 0px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background: #0C0C0C;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    display: flex;
    padding: 30px 44px 27px 43px;
    box-sizing: border-box;
  }
  
  .FolderAccess-SubContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .FolderAccess-nav1 {
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 670px;
    box-sizing: border-box;
  }
  .FolderAccess-Title{
    margin-right: 16.5px;
    width: 623.5px;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
  }
  .FolderAccess-icon-close {
    margin: 1px 0 2px 0;
    display: flex;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .FolderAccess-img-close{
    width: 30px;
    height: 30px;
  }
  .FolderAccess-line {
    background: #55585B;
    margin-bottom: 25px;
    width: 670px;
    height: 1px;
  }
  .FolderAccess-Choose{
    margin-bottom: 16px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .FolderAccess-nav2{
    margin: 0 0px 39px 0;
    display: flex;
    flex-direction: row;
    width: fit-content;
    box-sizing: border-box;
  }
  
  .all-users-container {
    border-radius: 10px;
    background: #181818;
    position: relative;
    margin-right: 23px;
    display: flex;
    flex-direction: row;
    padding: 17px 0 14px 23.5px;
    width: 323.5px;
    box-sizing: border-box;
  }
  .radio {
    border-radius: 999px;
    border: 1px solid #55585B;
    background: #313335;
    margin-right: 4px;
    width: 22px;
    height: 22px;
  }
  .all-users-text {
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
  .Onlyselect-container{
    border-radius: 10px;
    background: #181818;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 17px 0 14px 23.5px;
    width: 323.5px;
    box-sizing: border-box;
  }
  
  .Onlyselect-check {
    border-radius: 11px;
    background: #F0B41E;
    position: relative;
    margin-right: 4px;
    display: flex;
    padding: 6.9px;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
  }
  .elipse {
    border-radius: 4.1px;
    background: #0C0C0C;
    width: 8.3px;
    height: 8.3px;
  }
  .onlyselect-text {
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
  .FolderAccess-nav3 {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 670px;
    box-sizing: border-box;
  }
  
  .FolderAccess-nav3-text {
    margin-right: 11px;
    width: 473.8px;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }
  .select-all{
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 16px;
    color: #F0B41E;
    cursor: pointer;
  }
  
  .deselect-all {
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 16px;
    color: #F0B41E;
    cursor: pointer;
  }
  .FolderAccess-searchbox{
    border-radius: 10px;
    background: #181818;
    position: relative;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 29.6px 13px 24.9px;
    width: 670px;
    box-sizing: border-box;
  }
  .FolderAccess-searchbox-content {
    border-radius: 10px;
    position: relative;
    padding-bottom: 4px;
    width: 154.6px;
    height: 24px;
    box-sizing: border-box;
  }
  
  .FolderAccess-icon {
    display: flex;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
  }
  .FolderAccess-img {
    width: 20px;
    height: 20px;
  }
  .FolderAccess-searchbox-text{
    position: absolute;
    right: 0px;
    bottom: 0px;
    overflow-wrap: break-word;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: #757575;
  }
  
  .roles-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1e1e1e; /* Color de fondo de la tabla */
    color: #ffffff; /* Color del texto */
  }
  
  .roles-table th,
  .roles-table td {
    padding: 12px;
    border: 1px solid #333333; /* Color del borde */
    text-align: left;
  }
  
  .roles-table th {
    background-color: #333333; /* Color de fondo del encabezado */
  }
  
  .roles-table tbody tr:nth-child(odd) {
    background-color: #2c2c2c; /* Color de fondo para filas impares */
  }
  
  .roles-table tbody tr:nth-child(even) {
    background-color: #1e1e1e; /* Color de fondo para filas pares */
  }
  
  /* Estilos para las casillas de verificación */
  .checkbox {
    width: 18px;
    height: 18px;
    background-color: #2c2c2c;
    border: 2px solid #cccccc;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
  }
  
  .checkbox:checked {
    background-color: #ffd700;
    border-color: #cccccc;
    position: relative;
  }
  
  .checkbox:checked::before {
    content: '✔';
    color: #000000;
    position: absolute;
    top: -2px;
    left: 2px;
    font-size: 14px;
  }
  .FolderAccess-nav-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    width: fit-content;
    box-sizing: border-box;
  }
  .master-button-cancel-FolderAccess-Plus{
    margin-right: 16px;
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
  }
  .master-button-cancel-FolderAccess {
    border-radius: 10px;
    background: #1A1A1A;
    display: flex;
    padding: 6.5px 16.5px 6.5px 16px;
    box-sizing: border-box;
  }
  .button-cancel-FolderAccess-Text{
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 14px;
    line-height: 1.571;
    color: #8D9298;
  }
  
  .master-button-add-FolderAccess-Plus{
    display: flex;
    width: 138px;
    box-sizing: border-box;
  }
  
  .master-button-add-FolderAccess{
    border-radius: 10px;
    background: #F0B41E;
    display: flex;
    padding: 6.5px 0.2px 6.5px 0;
    width: 138px;
    height: fit-content;
    box-sizing: border-box;
    align-items: center;
    justify-content: center; /* Centrar horizontalmente */
    cursor: pointer;  
  }
  
  .button-add-FolderAccess-Text {
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 14px;
    line-height: 1.571;
    color: #181818;
    text-align: center; /* Añadir para centrar el texto horizontalmente */
  }
  
  
  .check_folder {
    border: 1px solid #55585B;
    background: #313335;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    border-radius: 50%; /* Hacerlo redondo */
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.check_folder.active {
    background: #F0B41E;
}

.check_folder.active::after {
    content: "";
    width: 10px;
    height: 10px;
    background: black;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
