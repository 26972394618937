.Calendar-Main {
    position: absolute;
    width: 100%; 
    height: 105vh; 
    top: 0;
    right: 0;
    background-color:  rgb(12,12,12);
}

.Calendar {
    position: absolute;
    width: 82%;
    height:auto; 
    top: 12vh;
    right: 0;
    background-color:  rgb(12,12,12);
}

.fc {
    color: #ffffff; /* Texto blanco */
  }

.fc-toolbar-title {
    color: #ff9800; /* Título del mes en naranja */
  }

  .fc-event:hover {
    background-color: #02a502; /* Azul más oscuro */
  }

.fc-daygrid-day {
    color: #fc0b0b !important; /* Texto de los días */
    
}

.fc-event {
    border: none; /* Sin borde */
    color: white; /* Texto blanco */
}

.fc-day-header {
    background-color: #2196f3; /* Cambia el color de fondo */

}

/* Estilos para el color de los días */
.fc-day-fri {
    color: #ff9800; /* Cambia el color del texto para el lunes */
}

.fc-col-header-cell{
    background-color: rgb(249, 180, 30);
}

.fc-col-header-cell-cushion{
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.fc-daygrid-day-number{
    color: #ffffff;
    text-decoration: none;
}

.fc-daygrid-more-link.fc-more-link{
    color: rgb(249, 180, 30);
}

.fc-popover-header {
    color: #ff9800;
    background-color: rgb(24,24,24) !important;
}

.fc-popover-body {
    background-color: rgb(12,12,12);
    border: none;
}

.fc-popover {
    background-color: #fc0b0b;
}

.fc-popover.fc-more-popover.fc-day.fc-day-sat.fc-day-today {
    border-color: rgb(249, 180, 30);
}