
.mac-book-air-1 {  
  width: 100%;
  height: 123vh;
  z-index: 300;
  background-color: rgb(12,12,12);
  font-family: 'Manrope', sans-serif;
}

.mac-book-air-1

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.users-header {
  position: absolute;
  font-size: 5vh;
  font-weight: 600;
  color: #F0B41E;
  margin-top: 15vh;
  margin-left: 45vh;
}

.form-container {
  display: block;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 5px; /* Reduce el espacio entre los recuadros */
  margin-top: 13vh;
}

.personal-information-section,
.terms-and-access-section {
  background-color: #181818;
  border-radius: 25px;
  padding: 10px 30px; /* Añade padding horizontal para mover el contenido hacia adentro */
  width: 70%;
  margin-left: 50vh; /* Centra el cuadro horizontalmente */
  margin-right: auto; /* Centra el cuadro horizontalmente */
  margin-bottom: 2.5vh;
}

.section-title {
  font-size: 3vh;
  font-weight: 600;
  color: #F0B41E;
  margin-bottom: 10px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5vh;
  padding-left: 10px; /* Añade padding izquierdo */
  padding-right: 0px; /* Añade padding derecho */
}

.input-group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 32%; /* Ajusta el ancho para que no se superpongan */
  padding-right: 10px; /* Añade padding a la derecha */
}


.password-toggle {
  position: absolute; /* Cambia a posición absoluta para poder ajustar */
  top: 50%; /* Coloca el botón en el centro vertical del input */
  transform: translateY(-50%); /* Corrige la posición según el tamaño del botón */
  right: 12px; /* Ajusta la posición desde el lado derecho */
  font-size: 20px; /* Ajusta el tamaño del icono si es un ícono de fuente */
  width: 35px; /* Ajusta el ancho del icono si es una imagen */
  height: 16px; /* Ajusta la altura del icono si es una imagen */
  background-color: #202021;
  padding: 0px;
}


.input-label {
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-bottom: -4px; /* Reduce el espacio entre el label y el input */
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.outline-button,
.filled-button {
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.outline-button {
  background: transparent;
  border: 1px solid #F0B41E;
  color: #F0B41E;
}

.filled-button {
  background: #F0B41E;
  border: none;
  color: #0C0C0C;
}

.address-group {
  width: 100%;
}

.address-input {
  border-radius: 14px !important;
  margin-bottom: 10px;
  margin-right: 10px;
  border-top-left-radius: 25px;
  border: 1px solid #55585B;
  background: #313335;
  padding: 12px;
  font-size: 12px;
  color: #8D9298;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  resize: vertical; /* Permite la redimensión vertical */
  min-height: 80px; /* Altura mínima del textarea */
}

.input-field {
  border-radius: 14px !important; /* Aumenta la redondez de las esquinas */
  margin-bottom: 10px; /* Ajusta el espacio vertical entre los inputs individuales dentro de cada grupo */
  margin-right: 10px; /* Ajusta el espacio horizontal entre los inputs dentro de cada grupo */
  border-top-left-radius: 25px; /* Asegúrate de que las esquinas superiores sean cuadradas */
  border: 1px solid #55585B;
  background: #313335;
  padding: 12px;
  font-size: 12px;
  color: #8D9298;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none; /* Asegúrate de que no haya sombras que afecten la visualización del borde */
}

.input-field[type="select"] {
  width: 100%;
  height: 43px;
}

@media (max-width: 768px) {
  .personal-information-section,
  .terms-and-access-section {
    background-color: #181818;
    border-radius: 25px;
    padding: 10px ; /* Añade padding horizontal para mover el contenido hacia adentro */
    width: 100%;
    margin-left: 1vh; /* Centra el cuadro horizontalmente */
    margin-right: auto; /* Centra el cuadro horizontalmente */
}
  .form-container {
    margin-top: 9vh;
    margin-left: -1vh;
  }
  .users-header {
    position: absolute;
    font-size: 3.5vh;
    font-weight: 600;
    color: #F0B41E;
    margin-top: 9vh;
    margin-left: 5vh;
  }

  .input-group {
    display: flex;
    flex-direction:column;
    position: relative;
    width: 32%; /* Ajusta el ancho para que no se superpongan */
    padding-right: 5px; /* Añade padding a la derecha */
  }

  .input-field {
    border-radius: 14px !important; /* Aumenta la redondez de las esquinas */
    margin-bottom: 5px; /* Ajusta el espacio vertical entre los inputs individuales dentro de cada grupo */
    margin-right: 10px; /* Ajusta el espacio horizontal entre los inputs dentro de cada grupo */
    border-top-left-radius: 25px; /* Asegúrate de que las esquinas superiores sean cuadradas */
    border: 1px solid #55585B;
    background: #313335;
    padding: 12px;
    font-size: 12px;
    color: #8D9298;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none; /* Asegúrate de que no haya sombras que afecten la visualización del borde */
  }

}