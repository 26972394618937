/* General Styling */
/* Ocultar barra de scroll en Webkit */
/* Webkit browsers */
.slider-container::-webkit-scrollbar {
    --scrollbar-width: none;
}

.slider-container::-webkit-scrollbar-thumb {
    background-color: #555; /* Color de la barra */
    border-radius: 2px; /* Bordes redondeados */
}

.slider-container::-webkit-scrollbar-track {
    background-color: #222; /* Color del fondo de la barra */
}

/* Firefox */
.slider-container {
    scrollbar-width: thin;
    scrollbar-color: #555 #222;
    --scrollbar-width: none !important;
}


.slider {
    display: block;
    position: fixed;
    background-color: rgb(12, 12, 12);
    top: 0;
    left: 0;
    width: 18%;
    height: 100vh;
    border-right: 2px solid rgb(52, 52, 52);
    overflow-y: auto; /* Agregado para permitir scroll si el contenido excede la altura */
    z-index: 1000; /* Mantiene el slider por encima del contenido principal */
    --scrollbar-width: none !important;
}

/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
    .slider {
        scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
        scrollbar-width: var(--scrollbar-width);
    }
}


.logo {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra el logo horizontalmente */
    padding: 10% 0; /* Ajusta el espacio alrededor del logo */
}

.logo a {
    width: 40%;
    height: auto;
    margin-right:-1vh;
}

.nav-bar {

    margin-top: -3vh;
    padding-bottom: 5vh;
}

.nav-bar22{
    margin-top: 2vh;
}

nav ul {

    list-style: none;
    margin: 0;
    padding: 1;
    padding-left: 1;
}

nav li {
    display: flex;
    align-items: center;
    padding: 10px 20px; /* Ajustado para un padding más coherente */
    cursor: pointer;
    font-family: Manrope, sans-serif; /* Asegura que se utilice la fuente adecuada */
    font-size: 1rem; /* Usa unidades relativas para mejor escalabilidad */
    font-weight: 500;
    color: white;
    line-height: 1.5;
}

.option1,
.option2 {

    color: rgb(133, 136, 137);
}

nav img {
    width: 15%;
    height: auto;
    margin-right: 10px; /* Espacio entre la imagen y el texto */
}

nav a {
    text-decoration: none;
    color: white;
    flex-grow: 1; /* Hace que el enlace ocupe el máximo espacio disponible */
}

.menu-arrow {
    margin-left: auto;
}

.menu-content {
    background-color: #333;
    border-radius: 10px;
    cursor: pointer;
    height: auto;
    width: 85%;
    margin: 1vh; /* Centra horizontalmente el menú */
    padding: 10px;
    display: inline-block;
    align-items:center;
    justify-content: space-between; /* Alinea los elementos del menú */
}

.submenu {
    padding-left: 20px;
    margin: 0;
    position: absolute;
    top: 63%;
    width: 100%;
}

.submenu-item:hover {
    background-color: rgb(12, 12, 12);
    border-radius: 25px;
    padding-left: 10px; /* Ajusta el padding para evitar el desplazamiento del texto */
}



/* Responsive Styling */
@media (max-width: 768px) {

    
    .slider {
        width: 70%;
        height: 100vh;
    }

    body.no-scroll {
        overflow:hidden;
    }

    .logo a, img  {
        width: 30%; /* Hace el logo más grande en pantallas pequeñas */
        margin-right: 1vh;
    }

    nav li {
        padding: 10px 10px; /* Reduce el padding en dispositivos pequeños */
        font-size: 1.2rem; /* Aumenta un poco el tamaño de la fuente */
    }

    nav img {
        width: 12%; /* Aumenta el tamaño de los íconos en el menú */
    }

    .menu-content {
        height: auto; /* Ajusta la altura del contenido del menú */
        width: 85%; /* Hace que el menú ocupe más espacio en pantallas pequeñas */
        display:block;
    }

    .submenu {
        position: relative; /* Cambia a relativo para evitar problemas en pantallas pequeñas */
        padding-left: 0;
    }

    .submenu-item {
        padding: 10px; /* Ajusta el padding para pantallas pequeñas */
    }
}
