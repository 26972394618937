.main-user-content{
    position: absolute;
    width: 82%;
    height: 150vh;
    padding-bottom: 52%;
    right: 0;
    background-color: rgb(12,12,12);    
}

.main-user-title{
    color: rgb(249, 180, 30);
    padding-left: 3%;
    padding-top: 2%;
}

.main2-user-content{
    width: 95%;
    height: 130vh;

    background-color: rgb(24,24,24);

    margin-left: 2.5%;
    margin-top: 3%;
    border-radius: 15px;
}

.black-user-content {
    position: absolute;
    background-color: rgb(12,12,12);
    width: 90%;
    height: 72%;
    top: 22%;
    left: 5%;

    border-radius: 25px;
}


.search-bar-2 {
    position: absolute;
    background-color: rgb(24, 24, 24);
    width: 60%;
    height: 8vh;
    top: 3.9vh;
    left: 20%;
    font-family: Manrope;
    border-radius: 10px;
  }
  
  .search-bar-2 input{
    position: relative;
    z-index: 1000 !important;
    outline-style: none;
    background-color: rgb(24, 24, 24);
    width: 90%;
    height: 100%;
    border-radius: 10px !important;
    left: 1%;
    border-style: none;
    color: white;
  }
  
  .search-bar-2 img {
    position: relative;
    display: inline;
    width: 4%;
    height: auto;
  }
  

  
  .sort-by-img {
    position: relative;
    background-size: cover; /* Use cover to maintain aspect ratio */
    background-repeat: no-repeat;
    background-color: rgb(19, 19, 19);
    width: 15%;
    height: 6vh; /* Set a fixed height for better control */
    display: inline-block; /* Use inline-block to respect width and height */
    margin-left: -5%;
    top: -0.2vh;
    left: -2vh;
    color:rgb(130, 130, 130);
    font-family: Manrope;
    background-position: center; /* Center the background image */
    align-content: center;
    border-radius: 5px;
  }
  
  
  .sort-by button {
    padding: 10px 20px;
    width: 50%;
    background-color:rgb(19, 19, 19);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    color:rgb(130, 130, 130);
    font-family: Manrope;
  }


  .sort-optionsUser {
    display: block;
    position: absolute;
    z-index: 1000;
    background-color:rgb(19, 19, 19);
    color: white;
    font-family: Manrope;
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .sort-optionsUser div {
    padding: 10px;
    cursor: pointer;
  }
  
  .sort-optionsUser div:hover {
    background-color: rgb(232, 167, 41);
    border-radius: 10px;
  }
 .div-button{
  padding-top: 5vh;
  padding-left: 140vh;
 }
  
  @media only screen and (max-width: 768px) {

    .main-user-content{
    position: absolute;
    width: 100%;
    height: 150vh;
    padding-bottom: 52%;
    right: 0;
    background-color: rgb(12,12,12);    
}
 .div-button{
  padding-top: 10vh;
  padding-left: 30vh;
 }

 .main2-user-content{
  width: 95%;
  height: 130vh;

  background-color: rgb(24,24,24);

  margin-left: 2.5%;
  margin-top: 25%;
  border-radius: 15px;
}
  .search-bar-2{
    top: 1%;
  }

  .main-user-title{
    position: absolute;
    top: 9%;
    left: 2%;
  }
  }