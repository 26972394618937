
.user-info {
    padding-left: 18%;
    background-color: rgb(12, 12, 12);
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.user-info-body {
    position: relative;
    background-color: rgb(24, 24, 24);
    top: 0;
    left: 0;
    width: 94%;
    height: 91vh;
    margin-top: 8%;
    margin-left: 3%;
    border-radius: 25px;
}

.user-info-body-int {
    position: absolute; /* Ensures the inner square is positioned relative to the outer square */
    top: 5%;
    right: 2.5%;
    bottom: 5%;
    width: 95%; /* Adjust width as needed */
    height: 88%; /* Adjust height as needed */
    font-size: 16px;
    padding: 10px;
    border-radius: 25px;
    background-color: rgb(12, 12, 12);;
}

.my-profile {
    color: rgb(232, 167, 41);
    font-size: 4vh;
    margin-top: 1.2%;
    margin-left: 3%;
    font-family: Manrope;
    font-weight: bold;
}

.user-info-body-int-1 {
    position: absolute; /* Ensures the inner square is positioned relative to the outer square */
    top: 12%;
    right: 2%;
    bottom: 5%;
    width: 96%; /* Adjust width as needed */
    height: 28%; /* Adjust height as needed */
    border-radius: 25px;
    background-color: rgb(24, 24, 24);
    align-content:initial;
}

.user-info-body-int-1 img {
    width: 13%;
    height: auto;
    border-radius: 100%;
    margin-left: 3%;
    margin-top: 1%;
    
}

.init-1-text1 {
    
    color: white;
    font-family: Manrope;
    font-size: 4vh;
    padding-left: 20%;
    margin-top: -11%;
    font-weight: bold;
}

.init-1-text2 {
    color: white;
    font-family: Manrope;
    font-size: 3vh;
    margin-left: 20%;
    margin-top: 0%;
    font-weight: bold;
}

.user-info-body-int-2 {
    position: absolute; /* Ensures the inner square is positioned relative to the outer square */
    top: 42.5%;
    right: 2%;
    bottom: 5%;
    width: 96%; /* Adjust width as needed */
    height: 55%; /* Adjust height as needed */
    border-radius: 25px;
    background-color: rgb(24, 24, 24);
}

.user-info-body-int-3 {
    position: absolute; /* Ensures the inner square is positioned relative to the outer square */
    top: 73%;
    right: 2%;
    bottom: 5%;
    width: 96%; /* Adjust width as needed */
    height: 20%; /* Adjust height as needed */
    border-radius: 25px;
    background-color: rgb(24, 24, 24);
}

.personal_info{
    color: rgb(232, 167, 41);
    font-family: Manrope;
    font-size: 2vh;
    margin-left: 3%;
    margin-top: 1%;
    font-weight: bold;
}

.init-2-text {
    max-width: 60%;
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
}

.init-2-text p {
    color: white;
    font-family: Manrope;
    font-size: 2vh;
    margin-top: 1%;
}

.init-3-text {
    max-width: 55%;
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
    margin-top: -2%;
}

.init-3-text p {
    color: white;
    font-family: Manrope;
    font-size: 2vh;
    margin-top: 1%;
    
}

.p2 {
    position: absolute;
    left: 30%;
}

.p3 {
    position: absolute;
    left: 55%;
}

.p4 {
    position: absolute;
    left: 30%;
}

.p5 {
    position: absolute;
    left: 55%;
}

.init-4-text {
    max-width: 55%;
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
    margin-top: -2%;
}

.init-4-text p {
    color: white;
    font-family: Manrope;
    font-size: 2vh;
    margin-top: 1%;
    
}

.p6 {
    position: absolute;
    left: 30%;
}

.p7 {
    position: absolute;
    left: 55%;
}

.access_control{
    color: rgb(232, 167, 41);
    font-family: Manrope;
    font-size: 2vh;
    margin-left: 3%;
    margin-top: -2%;
    font-weight: bold;
}

.init-5-text {
    max-width: 55%;
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
    margin-top: 1%;
}

.init-5-text p {
    color: white;
    font-family: Manrope;
    font-size: 2vh;
    margin-top: 1%;
    
}

.p8 {
    position: absolute;
    left: 30%;
}

.p9 {
    position: absolute;
    left: 55%;
}


.google-login{
    position: absolute;
    right: 5%;
    top: 35%;
    color: white;
}

.google-login2{
    display: none;
}

@media only screen and (max-width: 768px) {
    .user-info{
        position: absolute;
        padding-left: 0;
        padding-top: 5vh;
        height: 110vh;
    }

    .user-info-body{
        height: 95vh;
        padding:200% 0;
    }

    .user-info-body-int{
        height: 89vh;
        top: 1%;

    }

    .personal_info{
        color: rgb(232, 167, 41);
        font-family: Manrope;
        font-size: 2vh;
        margin-left: 3%;
        margin-top: 2%;
        font-weight: bold;
    }

    .my-profile {
        margin-left: 3.5vh;
    }

    .user-info-body-int-1{
        position: relative;
        height: 30vh;
        top: 1vh;
        left: 1vh;        
    }

    .user-info-body-int-2 {
        position: relative; /* Ensures the inner square is positioned relative to the outer square */
        top: 2vh;
        left: 1vh;
        bottom: 5%;
        width: 96%; /* Adjust width as needed */
        height: 55%; /* Adjust height as needed */
        border-radius: 25px;
        background-color: rgb(24, 24, 24);
    }

    .user-info-body-int-1 img {
        position: absolute;
        width: 15vh;
        height: auto;
        border-radius: 100%;
        margin-left: 12vh;
        margin-top: 9%;
        
    }

    .init-1-text1 {
        position: absolute;
        color: white;
        font-family: Manrope;
        font-size: 3vh;
        text-align:initial;
        top: 24vh;
        margin-right: 8vh;
        font-weight: bold;
        text-align: center;
    }
    
    .init-1-text2 {
        position: relative;
        color: white;
        font-family: Manrope;
        font-size: 2.7vh;
        text-align:initial;
        margin-top: 0%;
        font-weight:lighter;
    }
    
    .init-2-text, .p2, .p3 {
        justify-content: space-between;
        text-align: justify;
        margin-left: 5%;
    }

    .p3{
        left: 26vh;
    }

    .pn{
        margin-left: 1vh;
    }
    
    .p4 {
        position: absolute;
        left: 2vh;
        top: 20vh;
    }
    
    .p5 {
        position: absolute;
        top: 20vh;
        left: 55%;
    }
    
    .init-4-text {
        max-width: 55%;
        display: flex;
        justify-content: space-between;
        margin-left: 3%;
        margin-top: 6vh;
    }
    
    .init-4-text p {
        color: white;
        font-family: Manrope;
        font-size: 2vh;
        margin-top: 1%;
        margin-left: 1vh;
    }
    
    .p6 {
        position: absolute;
        left: 8vh;
    }
    
    .p7 {
        position: absolute;
        left: 55%;
    }
    
    .access_control{
        color: rgb(232, 167, 41);
        font-family: Manrope;
        font-size: 2vh;
        margin-left: 3%;
        margin-top: -2%;
        font-weight: bold;
    }
    
    .init-5-text {
        max-width: 55%;
        display:flex;
        justify-content:space-between;
        margin-left: 3%;
        margin-top: 1%;
        text-align:initial;
    }
    
    .init-5-text p {
        color: white;
        font-family: Manrope;
        font-size: 2vh;
        margin-top: 1%;
        margin-left: 1vh;
    }
    
    .p8 {
        position: absolute;
        left: 19vh;
    }

    .google-login{
        display: none;
        
    }

    .init-1-text2{
        position: absolute;
        display: none;
    }

    .google-login2{
        position: absolute;
        display:block;
        bottom: 2%;
        left: 30%;
    }
}