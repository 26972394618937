.forgot-password {
    background: #0C0C0C;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 29.5px 0 61px 0;
    width: 100%;
    box-sizing: border-box;
    position: relative; /* Añadido */
    z-index: 2; /* Añadido */
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.city-background {
    background: url('/src/pages/image/CityLoginVectorMesaDeTrabajo11.png') center / cover no-repeat;
    width: 100%;
    height: 100% ;
}

.overlay {
    background: url('/src/pages/image/NuevoFondoLogin021.png') center / cover no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 2; /* Añadido */
}

.logos {
    position: relative;
    margin: 0 0.7px 27px 0;
    display: flex;
    justify-content: center; /* Centra el logo horizontalmente dentro del contenedor */
    align-items: center; /* Centra el logo verticalmente dentro del contenedor */
    width: 115.5px; /* Ajustado al nuevo tamaño del logo */
    height: 124.5px; /* Ajustado para permitir más espacio vertical */
    box-sizing: border-box;
    z-index: 3;
}

.logo-forgot-password {
    width: 150.5px;
    height: 154.5px;
}


.forgot-password-container {
    opacity: 0.8;
    border-radius: 22px;
    background: #0C0C0C;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 34px 25px 41.2px 26px;
    width: 100%; /* Cambiado a 100% para ser responsivo */
    max-width: 400px; /* Define un ancho máximo */
    box-sizing: border-box;
    z-index: 3;
}


  .forgot-password-container-circle{
    border-radius: 56.5px;
    background: #F0B41E;
    position: relative;
    margin-bottom: 17px;
    display: flex;
    padding: 16.5px 17px 17.9px 17.4px;
    width: 113px;
    height: 113px;
    box-sizing: border-box;
  }
  .forgot-password-container-imgs{
    position: relative;
    display: flex;
    padding: 21.8px 0;
    width: 78.6px;
    height: 78.6px;
    box-sizing: border-box;
  }
  .forgot-password-icon{
    position: relative;
    display: flex;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
  }
  .forgot-password-img{
    width: 35px;
    height: 35px;
  }
  .forgot-password-icon2{
    position: absolute;
    left: 50%;
    bottom: 0px;
    translate: -50% 0;
    display: flex;
    width: 78.6px;
    height: 78.6px;
    box-sizing: border-box;
  }
  .forgot-password-img2{
    width: 78.6px;
    height: 78.6px;
  }
  .Reset-title{
    margin-bottom: 0px;
    display: inline-block;
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 24px;
    letter-spacing: -0.5px;
    color: #F0B41E;
    text-align: center;
  }
  
  .Reset-Subtext {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 12px;
    line-height: 1.333;
    text-align: center; /* Añadido */
}

.Reset-Subtext-text {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.333;
    color: #8D9298;
    text-align: center; /* Añadido */
}
.highlight {
    color: #F0B41E;
}


.forgot-user-input {
    border-radius: 8px;
    border: 0.5px solid #8D9298;
    background: #1A1A1A;
    position: relative;
    margin-bottom: 17px;
    display: flex;
    flex-direction: row;
    padding: 9px 0 9px 12.5px;
    width: 100%; /* Cambiado a 100% para ser responsivo */
    max-width: 312px; /* Define un ancho máximo */
    box-sizing: border-box;
}

.forgot-user-input-icon {
    border: 1px solid #1A1A1A;
    margin-right: 7.5px;
    display: flex;
    width: 22.5px;
    height: 24px;
    box-sizing: border-box;
}

.forgot-user-input-img {
    width: 22.5px;
    height: 24px;
}

.forgot-user-input-text {
    border: none;
    outline: none;
    background-color: #181818;
    font-weight: bold;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 18px;
    line-height: 1.333;
    color: #ffffff;
    flex-grow: 1;
}

.forgot-button-send, .forgot-button-cancel {
    border-radius: 10px;
    position: relative;
    margin-bottom: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12.2px 0 11.7px 0;
    width: 100%; /* Cambiado a 100% para ser responsivo */
    max-width: 247px; /* Define un ancho máximo */
    box-sizing: border-box;
    cursor: pointer;
}

.forgot-button-send {
    background: #F0B41E;
}

.forgot-button-send-text {
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #0C0C0C;
}

.forgot-button-cancel {
    background: #1A1A1A;
}

.forgot-button-cancel-text {
    overflow-wrap: break-word;
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #8D9298;
}

/* Media queries para ajustes responsivos */
@media (max-width: 768px) {
    .forgot-password-container {
        padding: 20px;
    }

    .forgot-user-input-text {
        font-size: 16px;
    }

    .forgot-button-send-text, .forgot-button-cancel-text {
        font-size: 14px;
    }
}
  
  .forgot-end-text{
  text-align: center;
  overflow-wrap: break-word;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 12px;
  line-height: 1.27;
  color: #8D9298;
  }
  
  .forgot-end-text-plus{
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.333;
    color:  #8D9298;
  }
  .error-message {
    font-family: 'Manrope', sans-serif;
    color: #ff4d4d; /* Rojo llamativo */
    padding: 10px 15px; /* Espaciado interno */
    margin-top: 0px; /* Espaciado superior */
    margin-bottom: 0px; /* Espaciado inferior */
    font-size: 1.8em; /* Tamaño de fuente ligeramente mayor */
    text-align: center; /* Centramos el texto */
    font-weight: 800; /* Negrita */
}