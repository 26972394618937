body, html {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

.log-in {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0C0C0C;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.city-background {
  background: url('/src/pages/image/CityLoginVectorMesaDeTrabajo11.png') center / cover no-repeat !important;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.login-container {
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  padding: 40px;
  width: 400px;
  text-align: center;
  box-sizing: border-box;
}

.logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.login-form h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #F0B41E;
}

.login-form p {
  font-size: 16px;
  margin-bottom: 30px;
  color: #8D9298;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
  width: 100% !important;
  padding: 0; /* Padding eliminado */
  box-sizing: content-box; /* Desactivado border-box */
}

.input-group label {
  display: block;
  font-size: 14px;
  color: #8D9298;
  margin-bottom: 5px;
}

.input-group input {
  width: 100% !important;
  padding: 10px;
  border: 1px solid #8D9298;
  border-radius: 8px !important;
  background: rgba(141, 146, 152, 0.07);
  color: #FFFFFF;
  font-size: 14px;
  box-sizing: border-box; /* Asegurarse que los inputs tienen box-sizing: border-box */
}

.input-group input:focus {
  outline: none;
  border-color: #F0B41E;
}

.password-group_login {
  position: relative;
  width: 100% !important;
  padding: 0; /* Asegurar que no hay padding */
}

.password-input-wrapper_login {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.password-input-wrapper_login input {
  flex: 1;
  padding-right: 40px;
  width: 100% !important;
}

.password-toggle-login {
  position: absolute;
  right: 0% !important;
  top: 70% !important;
  transform: translateY(-50%);
  cursor: pointer;
  color: #8D9298;
  background: none;
  border: none;
  font-size: 18px;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.options .remember-me {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.options .remember-me input {
  margin-right: 10px;
}

.options a {
  font-size: 12px;
  color: #8D9298;
  text-decoration: underline;
}

.login-button {
  background: #F0B41E;
  color: #0C0C0C;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  width: 100% !important;
}

.login-button:hover {
  background: #d99b17;
}

.signup {
  margin-top: 20px;
  font-size: 14px;
}

.signup a {
  color: #F0B41E;
  text-decoration: underline;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  background: #333;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.error-message_Login{
color: #ffffff;
font-size: 16px   ;
}

.google-login {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-login-button {
  background: #FFFFFF;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.google-login-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.google-login-button:hover {
  background: #e0e0e0;
}
