

.recents {
    padding-left: 18%;
}

.recents h1 {
    position: relative;
    top:-3vh;
    font-family: Manrope;
    font-size: 160%;
    font-weight: bold;
    color: white;
    margin-left: 2%;
    width: auto;
}

.activity-container {
    position: absolute;
    background-color: rgb(24, 24, 24);
    width: 77%;
    height: 110%;
    margin-left: 2%;
    margin-top: 1%;
    border-radius: 15px;
}

.activity-container h1 {
    position: relative;
    color: rgb(232, 167, 41);
    top: 3vh;
    padding-left: 1%;
}

.activity-container p {
    position: relative;
    padding-left: 2%;
    padding-left: 3%;
    color: white;
    font-size: 95%;
    top: 3vh;
}

.back-black {
    position: relative;
    background-color: rgb(12, 12, 12);
    width: 98%;
    height: 80%;
    margin-left: 1%;
    top: 4vh;
    border-radius: 15px;
}

.cotent-table {
    position: relative;
    background-color: rgb(12, 12, 12);
    width: 90%;
    height: 86%;
    margin-left: 5%;
    border-radius: 10px;
    top: 7vh;
    text-align:justify;
}

.activity-table {
    position: relative;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    top: -5%;
    text-align:justify;
    z-index: 0!important;
  }

  .activity-table-df {
    position: relative;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    top: -5%;
    text-align:justify;
    z-index: 0!important;
  }


  .activity-table th,
  .activity-table td {
    padding: 10px;
    text-align:left;
  }
  
  .activity-table-df th,
  .activity-table-df td {
    padding: 10px;
    text-align:left;
  }

  .activity-table th,
  .activity-table-df th {
    background-color: #3a3a3c;
    
  }
  
  .activity-table td {
    background-color: rgb(12, 12, 12);
    text-align:left !important;
    border-bottom-color: rgb(150, 150, 150);
    border-bottom-width: 0.1px;
  }

  .activity-table-df td {
    background-color: rgb(12, 12, 12);
    text-align:center !important;
    border-bottom-color: rgb(150, 150, 150);
    border-bottom-width: 0.1px;
  }
  
  .th1 {
    border-bottom-left-radius:10px ;
    text-align:justify;
  }

  .th2 {
    border-bottom-right-radius: 10px;
    text-align:justify;
  }

  .nav-move-pages2 {
    position: relative;
    top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

.search-bar {
  position: relative;
  background-color: rgb(24, 24, 24);
  width: 60%;
  height: 8vh;
  top: 3vh;
  left: 20%;
  font-family: Manrope;
  border-radius: 10px;
}

.search-bar input{
  position: relative;
  z-index: 1000 !important;
  outline-style: none;
  background-color: rgb(24, 24, 24);
  width: 90%;
  height: 100%;
  border-radius: 10px !important;
  left: 1%;
  border-style: none;
}

.search-bar img {
  position: relative;
  display: inline;
  width: 4%;
  height: auto;
}

.sort-by {
  position: relative;
  display: flex;
  width: 50%;
  top: 3vh;
  left: 8%;
}

.sort-by-img {
  position: relative;
  background-size: cover; /* Use cover to maintain aspect ratio */
  background-repeat: no-repeat;
  background-color: rgb(19, 19, 19);
  width: 15%;
  height: 6vh; /* Set a fixed height for better control */
  display: inline-block; /* Use inline-block to respect width and height */
  margin-left: -5%;
  top: -0.2vh;
  left: -2vh;
  color:rgb(130, 130, 130);
  font-family: Manrope;
  background-position: center; /* Center the background image */
  align-content: center;
  border-radius: 5px;
}


.sort-by button {
  padding: 10px 20px;
  width: 50%;
  background-color:rgb(19, 19, 19);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  color:rgb(130, 130, 130);
  font-family: Manrope;
}

.sort-options {
  display: block;
  position: absolute;
  z-index: 1000;
  background-color:rgb(19, 19, 19);
  color: white;
  font-family: Manrope;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.sort-options div {
  padding: 10px;
  cursor: pointer;
}

.sort-options div:hover {
  background-color: rgb(232, 167, 41);
  border-radius: 10px;
}


@media (max-width: 768px) {

  .nav-move-pages2 {
    
    margin-top: 40px;
  }

  .activity-table {
   height:90%;
  }

}