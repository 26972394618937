.workplace-container{
    background-color: rgb(12, 12, 12);
    width: 100%;
    padding-bottom: 100vh;
}


.loading {
    width: 100%;
    height: 100vh;
    background-color: black;
    align-content:center;
    text-align: center;

}

.fc-scrollgrid.fc-scrollgrid-liquid::-webkit-scrollbar {
    --scrollbar-width: none !important;
    display: none;
}

.calendar .fc-toolbar-title{
    font-size: 2.4vh;

}
.fc-toolbar-chunk{
    align-content: center;
}

.main-contect{
    position: absolute;
    background-color: rgb(24, 24, 24);
    width: 60%;
    height:85vh;
    left: 44.5vh;
    top: 15vh;
    border-radius: 25px;
}

.main-title{
    color: white;
    position:absolute;
    top: 4vh;
    left: 45vh;
    font-family: Manrope;
}

.calendar{
    /*position*/
    position: absolute;
    bottom: 3vh;
    left: 3vh;

    /*style*/
    background-color: rgb(12, 12, 12);
    width: 35%;
    height: 50%;
    border-radius: 25px;

    /*temporal*/
    color: white;
    align-content: center; 
    text-align-last: center;  
    
}

.recent-document {
    /* position*/
    position: absolute;
    top: 3vh;
    left: 3vh;
    
    /*Style*/
    background-color: rgb(12, 12, 12);
    width: 45%;
    height: 40%;
    border-radius: 25px;

    /*temporal*/
    color: white;
    align-content: center;
    text-align: center;
}

.list-chats{
    /*position*/
    position: absolute;
    right: 3vh;
    bottom: 3vh;

    /*style*/
    background-color: rgb(12, 12, 12);
    width: 58%;
    height: 50%;
    border-radius: 25px;

    /*temporal*/
    color: white;
    align-content: center;
    text-align: center;
}

.idk {
    /*position*/
    position: absolute;
    top: 3vh;
    right: 3vh;

    /*style*/
    background-color: rgb(12, 12, 12);
    width: 48%;
    height: 40%;
    border-radius: 25px;

    /*temporal*/
    color: white;
    align-content: center;
    text-align: center;
}


/* ImgSlider*/
.sidebar {
    position: absolute;
    width: 17%;
    height: 85vh;
    right: 1%;
    top: 14%;
    border-radius: 2%;
}

.slider-container2 {
    position: relative;
    width: 100%;
    height: 85vh;

    overflow: hidden;
    border-radius: 2%;
  }
  
  .slider2 {
    display: flex;
    transition: transform 0.5s ease-in-out;
    height: 100%;
    border-radius: 2%;

  }
  
  .slide2 {
    min-width: 100%;
    height: 100%;
    border-radius: 2%;
    box-sizing: border-box;
  }
  
  .slide2 img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 20px;
  }
  
  .navigation2 {
    position: absolute;
    bottom: 10px;
    width: 100%;
    
    display: flex;
    justify-content: center;
  }
  
  .nav-button2 {
    border: none;
    background-color: rgba(49, 51, 53, 0.5);
    margin: 0 5px;
    padding: 5px;

    border-radius:50%;
    cursor: pointer;

  }

  .calendar h3 a{
    color: rgba(249, 180, 30, 0.9);
}
  
  .nav-button2.active2 {
    background-color: rgba(249, 180, 30, 0.9);
  }
  

@media(max-width :768px) {
    .workplace-container{
        padding-bottom: 163vh;
    }
    .main-contect{
        position: absolute;
        background-color: rgb(24, 24, 24);
        width: 95%;
        left: 1vh;
        top: 10vh;
        border-radius: 25px;
        padding-bottom: 153vh;
    }

    .main-title{
        color: white;
        position:absolute;
        top: 3vh;
        left: 9vh;
        font-family: Manrope;
    }

    .calendar{
        /*position*/
        position: absolute;
        top: 3vh;
        left: 3vh;
    
        /*style*/
        background-color: rgb(12, 12, 12);
        width: 40vh;
        height: 50vh;
        border-radius: 25px;

        
    }

    

    .idk {
        display: none;
    }

    .recent-document{
        /*poition*/
        top: 56.3vh;

        /*style*/
        width: 40vh;
        height: 35vh;
    }

    .list-chats{
        z-index: 50000;
        top: 95vh;

        width: 40vh;
        height: 55vh;
    }
    
}