.app {
    /*position*/
    position: absolute;
    right: 0;

    /*style*/
    font-family: Manrope;
    background-color: rgb(12, 12, 12);
    color: white;
    width: 82%;
    height: 100%;
}

.header {
    background-color: rgb(12, 12, 12);
    padding: 10px;
    text-align: center;
}

.header h1{
    position: absolute;
    margin-top: 1.5%;
    font-size: 5vh;
}

.main {
    display: flex;
    height: calc(100vh - 100px);
    margin-top: 4%;
}

.sidebarE {
    margin-top: 2%;
    width: 30%;
    /*border-right: 1px solid #ccc;
    /*border-top: 1px solid #ccc;*/
    overflow-y: auto;
}

.content {
    width: 70%;
    padding: 20px;
    overflow-y: auto;
}

.sidebarE::-webkit-scrollbar{
    display: none;
}

.emailItem {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.selectedEmail {
    background-color: rgb(24, 24, 24);
    border-left: 4px solid rgb(249, 180, 30);
}

.sender {
    font-weight: bold;
}

.subject {
    margin-top: 5px;
}

.preview {
    color: #666;
    font-size: 0.9em;
    margin-top: 5px;
}

.emailDetail {
    padding: 20px;
    background-color: rgb(24, 24, 24);
    height: 100%;
    border-radius: 25px;
}

.emailBody {
    margin-top: 20px;
    line-height: 1.6;
}

.composeButton {
    width: 100%;
    padding: 10px;
    background-color: rgb(249, 180, 30);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.composeForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
}

.input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: rgb(24, 24, 24);
    color: white;
}

.textarea {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 200px;
    resize: vertical;
    background-color: rgb(24, 24, 24);
    color: white;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.sendButton {
    padding: 10px 20px;
    background-color:rgb(249, 180, 30);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancelButton {
    padding: 10px 20px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.deleteButton {
    padding: 10px 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.content-email-not{
    background-color: rgb(12, 12, 12);
    width: 100%;
    height: 100vh;

    align-content: center;
    text-align: center;
    color: rgb(249, 180, 30);
    font-size: 3vh;
    
}

.content-email-not a{
    color: rgb(249, 180, 30);
}

@media (max-width:768px){
    .app{
        left: 0;
        width: 100%;
        height: 110vh;
    }
        .main{
            margin-top: 15%;
            width: 100%;
            height: auto;
        }

        .emailDetail{
            position: absolute;
            top: 0%;
            right: 0%;
            width: 100%;
            height: 100%;
            z-index: 200000;
        }

        .sidebarE{
            position: absolute;
            width: 100%;
            height: 100vh;
        }

        .header h1{
            left: 19%;
            top: -0.2%;
        }

        .hidden{
            display: none;
        }
        .nohidden{
            display: auto;
        }

        .closeButton {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: transparent;
            border: none;
            font-size: 20px;
            cursor: pointer;
            color: white;
          }

        .composeFormMain{
            position: absolute;
            z-index: 9000000 !important;
            background-color: rgb(24, 24, 24);
            padding:20% 10%;
            right: 1%;
            top: 10%;
            border-radius:25px ;
        }

        .composeForm{
            position: relative;
            display: flex;
            flex-direction: column;
            
        }

        
}